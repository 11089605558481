import { useEffect , useRef, useState} from "react"
import { useNavigate } from "react-router-dom"

import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints"
import apiCall from "../../utils/apiCall"
import { whitelabel_id } from "../../constants/ids"
import toastr from 'toastr'

const CposapikeyDetails = ({ arg }) => {
	const navigate = useNavigate()
	const statuses = JSON.parse(localStorage.getItem('statuses'))
	const authUser = JSON.parse(localStorage.getItem('auth_user'))
	const [loading, setLoading] = useState(false)
	
	console.log(authUser);
	 const [inputs, setInputs] = useState({
        webhook_url: arg.webhook_url,
        ip: arg.ip, 
    })
	
    useEffect(() => {
        if (!authUser) {
            navigate('/login')
        }
        if (authUser && authUser?.is_live !== 1) {
            if (statuses.company_status != "2" || statuses.director_status != "2" || statuses.document_status != "2") {
                navigate('/account-confirmation')
            }
        }
    })
	
	const updateapikey = async () => {
		
		setLoading(true)
		 
		const { web_token, user_id } = authUser
		
		const body = {
            whitelabel_id: whitelabel_id,
            user_id: user_id,
            web_token: web_token, 
            ip: inputs?.ip,
            webhook_url: inputs?.webhook_url,
        }		
		const url = API_ENDPOINTS.UPDATE_API_KEY_SETTING
		const response = await apiCall(url, API_METHODS.POST, body)			   
        if (response?.status == 1) {
            toastr.success(response?.message || 'Api key updated successfully')           
           setLoading(false)
        } else if (response?.status == 0) {
            toastr.error(response?.message || 'unable to add Successfully')
           setLoading(false)
        } else {
            console.error(response?.error || 'unable to add Successfully')
            setLoading(false)
        }
	}
    return (
        <>
            <div className="col-lg-8 col-md-10 col-sm-12">
                <div className="card">
                    <div className="card-header border-0">
                        <h3>
							Crypto Merchant Api Key Details 
                            
                        </h3>
                    </div>
                    <div className="card-body">
						<div className="d-flex justify-content-between">
                            <span >Api Key </span>
                            <span >{arg?.apikey}</span>
                        </div>
						<div className="d-flex justify-content-between">
                            <span >Api Status </span>
                            <span >{arg?.status == 1 ? <i class="bi bi-hourglass-split me-1 text-success">Enabled</i> :
                                                    <i class="bi bi-check2-circle me-1 text-danger">Disabled</i>
                                                }</span>
                        </div>
                        <hr />						
                        <hr />						
															
						<div className="mb-3 row">
							<label className="col-lg-4 col-form-label"	htmlFor="validationCustom09">Webhook URL<span className="text-danger">*</span></label>
							<div className="col-lg-6">
								<input
									type="text"
									value={inputs?.webhook_url || ''}	
									onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, webhook_url: e.target.value }))}	
									className="form-control"
									placeholder={"Enter webhook url"}
								/>								
							</div>
						</div>						
						<div className="mb-3 row">
							<label className="col-lg-4 col-form-label"	htmlFor="validationCustom09">IP Address<span className="text-danger">*</span></label>
							<div className="col-lg-6">
								<input
									type="text"
									value={inputs?.ip || ''}
									onChange={(e) => setInputs((prevInputs) => ({ ...prevInputs, ip: e.target.value }))}
									className="form-control"
									placeholder={"Enter IP Address"}
								/>								
							</div>
						</div>
						
						<div className="mb-3 row">
							<label className="col-lg-4 col-form-label"	htmlFor="validationCustom09"></label>
							<div className="col-lg-6">
								<button disabled={loading} type="button" onClick={updateapikey} class="btn btn-default d-flex justify-content-center align-items-center gap-2">
                                        Update  {loading && <div class="spinner-border" style={{ height: "16px", width: "16px" }} role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>}
                                   </button>					
							</div>
						</div>
						
                    </div>
                </div>
            </div>
        </>
    )
}
export default CposapikeyDetails