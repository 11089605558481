import { useNavigate } from "react-router-dom"
import AddressDetails from "./addressDetails"
import CompanyDetails from "./companyDetails"
import { useEffect, useState } from "react"
import { API_ENDPOINTS, API_METHODS } from "../../constants/endpoints"
import apiCall from "../../utils/apiCall"
import { whitelabel_id } from "../../constants/ids"
import toastr from 'toastr'
import DirectorDetails from "./directorDetails"
import CposapikeyDetails from "./cposapikeyDetails"

const Profile = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const statuses = JSON.parse(localStorage.getItem('statuses'))
    const authUser = JSON.parse(localStorage.getItem('auth_user'))
    const [companyDetails, setCompanyDetails] = useState({})
    const [ApikeyDetail, setApikey] = useState({})
    const [directorDetails, setDirectorDetails] = useState({})
    const [user,setUser]=useState({})
    useEffect(() => {
        if (!authUser) {
            navigate('/login')
        }
        if (authUser && authUser?.is_live !== 1) {
            if (statuses.company_status != "2" || statuses.director_status != "2" || statuses.document_status != "2") {
                navigate('/account-confirmation')
            }
            navigate('/account-confirmation')
        }
        getProfileDetails()
      
    }, [])
    const getProfileDetails = async () => {
        setLoading(true)
        const { web_token, user_id } = authUser
		//console.log(user_id);
        const url = API_ENDPOINTS.PROFILE_DETAILS
        const body = {
            whitelabel_id: whitelabel_id,
            web_token: web_token,
            user_id: user_id,
        }
		
        const response = await apiCall(url, API_METHODS.POST, body)
        if (response?.company && response?.director) {
            setLoading(false)
            setDirectorDetails(response?.director)
            setCompanyDetails(response?.company)
            setUser(response?.user)
            setApikey(response?.apikey)
            setLoading(false)
        } else if (response?.status == 0) {
            toastr.error(response?.message)
            setLoading(false)
        } else {
            console.error('Unable to fetch.')
            setLoading(false)

        }
    }
   
    return (
        <>
            {!loading ? <>
                <div class="d-flex align-items-center mb-4 flex-wrap">
                    <div class="card-tabs mt-3 mt-sm-0 me-auto">
                        <ul class="nav nav-tabs trans-tab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link underline active" data-bs-toggle="tab" href="#company-details" role="tab">Company Details</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link underline" data-bs-toggle="tab" href="#address-details" role="tab">Address Details</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link underline" data-bs-toggle="tab" href="#director-details" role="tab">Director Details</a>
                            </li>
							<li class="nav-item">
                                <a class="nav-link underline" data-bs-toggle="tab" href="#apikey-details" role="tab">Crypto Merchant Apikey</a>
                            </li>
                        </ul>
                    </div>




                </div>
                <div class="row">
                    <div class="tab-content">
                        <div class="tab-pane fade active show" id="company-details" role="tabpanel">
                            <CompanyDetails company={companyDetails || {}} user={user || {}} />
                        </div>
                        <div class="tab-pane fade  show" id="address-details" role="tabpanel">
                            <AddressDetails company={companyDetails || {}} />
                        </div>
                        <div class="tab-pane fade  show" id="director-details" role="tabpanel">
                            <DirectorDetails director={directorDetails || {}} />
                        </div>
						<div class="tab-pane fade  show" id="apikey-details" role="tabpanel">
                            <CposapikeyDetails arg={ApikeyDetail || {}} />
                        </div>
                    </div>
                </div>
            </> :
                <div className="text-center my-5 ">
                    <div class="spinner-border" style={{ height: "50px", width: "50px" }} role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>}
        </>
    )
}
export default Profile